import { TaskTemplateSpec } from 'src/app/models/task';

export class LoadIncidentSchemaDetails {
  static readonly type = '[Incident] Load Incident Schema Details';
  constructor(public id: string) { }
}

export class LoadIncidentSchemaTaskTemplateLinks {
  static readonly type = '[Incident] Load Incident Schema Task Template Links';
  constructor(public id: string) { }
}

export class CreateIncidentSchemaTaskTemplateAction {
  static readonly type = '[Incident] Create Incident Schema Task Template';
  constructor(public taskTemplateSpec: TaskTemplateSpec) { }
}

export class UpdateIncidentSchemaTaskTemplateAction {
  static readonly type = '[Incident] Update Incident Schema Task Template';
  constructor(
    public taskTemplateLinkId: string,
    public taskTemplateSpec: TaskTemplateSpec
  ) { }
}

export class DeleteIncientSchemaTaskTemplateLinkAction {
  static readonly type = '[Incident] Delete Incident Schema Task Template Link';
  constructor(public linkId: string) { }
}
